.vl {
  position: absolute;
  border-right: var(--cross-esterman-border-width) solid black;
  height: var(--cross-esterman-height);
  margin-left: var(--half-width);
}

.hl {
  position: absolute;
  border-bottom: var(--cross-esterman-border-width) solid black;
  width: var(--cross-esterman-width);
  margin-top: var(--half-height);
}

/* General styles for checkpoints on Y axis */
.checkPointYNeg,
.checkPointYPos {
  position: absolute;
  border-bottom: var(--cross-esterman-border-width) solid black;
  width: var(--cross-esterman-tick-size);
  left: var(--half-width); /* center it horizontally */
  transform: translateX(-50%); /* ensure it's perfectly centered */
}

/* Styles for checkpoints on X axis */
.checkPointXNeg,
.checkPointXPos {
  position: absolute;
  border-right: var(--cross-esterman-border-width) solid black;
  height: var(--cross-esterman-tick-size);
  top: var(--half-height); /* center it vertically */
  transform: translateY(-50%); /* ensure it's perfectly centered */
}

/* Dynamic styles for checkpoints */
.checkPointYNeg {
  top: calc(
    var(--half-height) + var(--half-height) / var(--tick-num) * var(--index)
  );
}
.checkPointYPos {
  top: calc(
    var(--half-height) - var(--half-height) / var(--tick-num) * var(--index)
  );
}
.checkPointXNeg {
  left: calc(
    var(--half-width) - var(--half-width) / var(--tick-num) * var(--index)
  );
}
.checkPointXPos {
  left: calc(
    var(--half-width) + var(--half-width) / var(--tick-num) * var(--index)
  );
}

.posX1 {
  position: absolute;
  margin-top: calc(var(--half-height) - 12px);
  left: -20px;
  /* margin-left: calc(var(--half-width) - 10); */
}
.posX2 {
  position: absolute;
  margin-top: calc(var(--half-height) - 12px);
  left: calc(var(--cross-esterman-width) + 5px);
}
