*, ::before, ::after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  
}
::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  
}
.container {
    width: 100%
}
@media (min-width: 640px) {
    .container {
        max-width: 640px
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 768px
    }
}
@media (min-width: 1024px) {
    .container {
        max-width: 1024px
    }
}
@media (min-width: 1280px) {
    .container {
        max-width: 1280px
    }
}
.\!visible {
    visibility: visible !important
}
.visible {
    visibility: visible
}
.static {
    position: static
}
.fixed {
    position: fixed
}
.absolute {
    position: absolute
}
.relative {
    position: relative
}
.sticky {
    position: sticky
}
.mx-auto {
    margin-left: auto;
    margin-right: auto
}
.my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
}
.my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem
}
.my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem
}
.mb-4 {
    margin-bottom: 1rem
}
.mb-5 {
    margin-bottom: 1.25rem
}
.mr-1 {
    margin-right: 0.25rem
}
.mr-3 {
    margin-right: 0.75rem
}
.mt-5 {
    margin-top: 1.25rem
}
.block {
    display: block
}
.inline-block {
    display: inline-block
}
.inline {
    display: inline
}
.flex {
    display: flex
}
.inline-flex {
    display: inline-flex
}
.table {
    display: table
}
.grid {
    display: grid
}
.hidden {
    display: none
}
.h-2 {
    height: 0.5rem
}
.h-2\.5 {
    height: 0.625rem
}
.h-4 {
    height: 1rem
}
.h-6 {
    height: 1.5rem
}
.w-2 {
    width: 0.5rem
}
.w-2\.5 {
    width: 0.625rem
}
.w-6 {
    width: 1.5rem
}
.w-full {
    width: 100%
}
.max-w-4xl {
    max-width: 56rem
}
.max-w-screen-xl {
    max-width: 1280px
}
.flex-grow {
    flex-grow: 1
}
.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.cursor-pointer {
    cursor: pointer
}
.resize {
    resize: both
}
.flex-col {
    flex-direction: column
}
.items-start {
    align-items: flex-start
}
.items-center {
    align-items: center
}
.justify-start {
    justify-content: flex-start
}
.justify-center {
    justify-content: center
}
.gap-1 {
    gap: 0.25rem
}
.gap-1\.5 {
    gap: 0.375rem
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))
}
.space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)))
}
.break-all {
    word-break: break-all
}
.rounded-full {
    border-radius: 9999px
}
.rounded-lg {
    border-radius: 0.5rem
}
.rounded-md {
    border-radius: 0.375rem
}
.border {
    border-width: 1px
}
.border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgb(220 38 38 / var(--tw-border-opacity))
}
.bg-baseDark {
    --tw-bg-opacity: 1;
    background-color: rgb(32 35 56 / var(--tw-bg-opacity))
}
.bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}
.bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(156 163 175 / var(--tw-bg-opacity))
}
.bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(34 197 94 / var(--tw-bg-opacity))
}
.bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 242 242 / var(--tw-bg-opacity))
}
.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}
.bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops))
}
.from-skyblue {
    --tw-gradient-from: #35bcfd var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(53 188 253 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.to-maxBlue {
    --tw-gradient-to: #3179FE var(--tw-gradient-to-position)
}
.p-2 {
    padding: 0.5rem
}
.p-4 {
    padding: 1rem
}
.p-6 {
    padding: 1.5rem
}
.px-0 {
    padding-left: 0px;
    padding-right: 0px
}
.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
}
.px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem
}
.px-4 {
    padding-left: 1rem;
    padding-right: 1rem
}
.px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem
}
.py-0 {
    padding-top: 0px;
    padding-bottom: 0px
}
.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
}
.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
}
.py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem
}
.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem
}
.py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem
}
.pt-14 {
    padding-top: 3.5rem
}
.pt-4 {
    padding-top: 1rem
}
.text-left {
    text-align: left
}
.text-center {
    text-align: center
}
.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem
}
.font-bold {
    font-weight: 700
}
.font-medium {
    font-weight: 500
}
.capitalize {
    text-transform: capitalize
}
.normal-case {
    text-transform: none
}
.italic {
    font-style: italic
}
.text-\[\#4471DB\] {
    --tw-text-opacity: 1;
    color: rgb(68 113 219 / var(--tw-text-opacity))
}
.text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity))
}
.text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity))
}
.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}
.text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgb(234 179 8 / var(--tw-text-opacity))
}
.underline {
    text-decoration-line: underline
}
.shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px
}
.grayscale {
    --tw-grayscale: grayscale(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}
.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}
.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}
.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}
.duration-300 {
    transition-duration: 300ms
}
.ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}
.hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(219 234 254 / var(--tw-bg-opacity))
}
.hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgb(96 165 250 / var(--tw-text-opacity))
}
@media (min-width: 640px) {
    .sm\:pt-0 {
        padding-top: 0px
    }
}
@media (prefers-color-scheme: dark) {
    .dark\:bg-gray-200 {
        --tw-bg-opacity: 1;
        background-color: rgb(229 231 235 / var(--tw-bg-opacity))
    }
}
